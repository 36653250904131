import { render, staticRenderFns } from "./ShareRecord.vue?vue&type=template&id=434a1c2e&scoped=true&"
import script from "./ShareRecord.vue?vue&type=script&lang=js&"
export * from "./ShareRecord.vue?vue&type=script&lang=js&"
import style0 from "./ShareRecord.vue?vue&type=style&index=0&id=434a1c2e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434a1c2e",
  null
  
)

export default component.exports