<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="邀请好友" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
		<template #right>
		  <span class="nav-right" @click="$router.push({path:'/ShareRecord'})">邀请记录</span>
		</template>
      </van-nav-bar>
      <div class="info">
      		<p class="title"><van-image class="game_item_img" :src="img_url">
      							<template v-slot:loading>
      								<van-loading type="circular" />
      							</template>
      							
      						</van-image></p>
			<p class="title">已邀请{{userInfo.yaonum}}人</p>
			
              <p class="title">您的邀请码</p>
              <p class="value">{{userInfo.code}}</p>
      		
      		<p style="margin-top: 50px;"><van-button round="true" @click="copyurl" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">链接分享</van-button></p>
              
        </div>
		
    </div>
	<div class="">
		<img  src="https://hmxxip.com/uploads/sharebg.png" style="border-radius: 20px 20px 0 0;     width: 100%;    margin-top: -17px;"/>
		<img  src="https://hmxxip.com/uploads/c.png" style="    width: 100%;    margin-top: -5px;"/>
		
	</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		userInfo:{
		},
      win_money:0,
	  img_url:'',
      personalreport:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
	copyurl() {
		
		      const input = document.createElement('input');
		
		      input.setAttribute('value', 'https://hmxxip.com/#/Register?code='+this.userInfo.code);
		
		      document.body.appendChild(input);
		
		      input.select();
		
		      document.execCommand('copy');
		
		      document.body.removeChild(input);
		
		      alert('复制成功！');
		
	},
	getUserInfo(){
		  this.$http({
		    method: 'get',
		    url: 'user_info'
		  }).then(res=>{
		    if(res.code === 200){
		        this.userInfo = res.data;
		        this.img_url = res.data.ewm;
		    }else if(res.code ===401){
		      this.$toast(res.msg);
		    }
		  })
	},
    getPersonalreport(){
      this.$http({
        method: 'get',
        url: 'user_get_personalreport'
      }).then(res=>{
        if(res.code === 200){
          this.personalreport = res.data;
          this.win_money = this.personalreport.win_money - this.personalreport.play_money;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
		this.getUserInfo();
      this.getPersonalreport();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .header{
  background: url('https://hmxxip.com/uploads/b14ad2a2ef17c799c7126c217afd16d4.jpg') no-repeat center center/100% 100%;
}
.container .header .info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}
.container .header .info .title{
  font-size: 25px;
  color: #e5e5e5;
}
.container .header .info .value{
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}
.container .header .info .tip{
  font-size: 30px;
  color: #e5e5e5;
}
.container .content{
  flex: 1;
  background: #f2f2f5;
}
.container .content .datalist{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.container .content .datalist .datalistitem{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 33.3%;
}
.container .content .datalist .datalistitem .datalistitemValue{
  color: #ff253f;
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.container .content .datalist .datalistitem .datalistitemKey{
  color: #979799;
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
