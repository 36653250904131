<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="会员级别" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <div  v-model="isLoading" @refresh="onRefresh">
		  <!--
		  <div style="border-bottom: 1px solid #000; height: 40px;     margin-top: 10px;">
			  <span style="width: 20%;     display: inline-block; text-align: left;">白银</span>
			  <span style="width: 20%;     display: inline-block; text-align: left;">￥188</span>
			  <span style="width: 60%;     display: inline-block; text-align: right;"><van-button class="sign-out" type="primary" size="normal" @click="uplevel(1)" style=" font-size: 16px; padding:16px; border-radius: 5px;">充值</van-button></span>
		  </div>
		  -->
		  <div style="border-bottom: 1px solid #000; height: 40px;     margin-top: 10px;">
		  			  <span style="width: 20%;     display: inline-block; text-align: left;">黄金</span>
		  			  <span style="width: 20%;     display: inline-block; text-align: left;">￥88</span>
		  			  <span style="width: 60%;     display: inline-block; text-align: right;"><van-button class="sign-out" type="primary" size="normal" @click="showp(2)" style=" font-size: 16px; padding:16px; border-radius: 5px;">充值</van-button></span>
		  </div>
		  
		  <div v-if="showpay2" style="left:0px;">
		  			 <van-button class="sign-out" type="primary" size="normal" @click="uplevel(2, 1)" style=" font-size: 16px; padding:16px; border-radius: 5px;">微信充值</van-button><van-button class="sign-out" type="primary" size="normal" @click="uplevel(2,2)" style=" font-size: 16px; padding:16px; border-radius: 5px; background-color:#1777ff; margin-left: 3px;;">支付宝充值</van-button>
		</div >
					  
					  
		  <div style="border-bottom: 1px solid #000; height: 40px;     margin-top: 10px;">
		  			  <span style="width: 20%;     display: inline-block; text-align: left;">钻石</span>
		  			  <span style="width: 20%;     display: inline-block; text-align: left;">￥158</span>
		  			  <span style="width: 60%;     display: inline-block; text-align: right;"><van-button class="sign-out" type="primary" size="normal" @click="showp(3)" style=" font-size: 16px; padding:16px; border-radius: 5px;">充值</van-button></span>
		  </div>
		  <div  v-if="showpay3" style="left:0px;">
		  			  <van-button class="sign-out" type="primary" size="normal" @click="uplevel(3, 1)" style=" font-size: 16px; padding:16px; border-radius: 5px;">微信充值</van-button><van-button class="sign-out" type="primary" size="normal" @click="uplevel(3,2)" style=" font-size: 16px; padding:16px; border-radius: 5px; background-color:#1777ff; margin-left: 3px;;">支付宝充值</van-button>
			</div >
					  
					  
		  <div style="border-bottom: 1px solid #000; height: 40px;     margin-top: 10px;">
		  			  <span style="width: 20%;     display: inline-block; text-align: left;">至尊</span>
		  			  <span style="width: 20%;     display: inline-block; text-align: left;">￥188</span>
		  			  <span style="width: 60%;     display: inline-block; text-align: right;"><van-button class="sign-out" type="primary" size="normal" @click="showp(4)" style=" font-size: 16px; padding:16px; border-radius: 5px;">充值</van-button></span>
		  </div>
		  <div  v-if="showpay4" style="left:0px;">
			  <van-button class="sign-out" type="primary" size="normal" @click="uplevel(4, 1)" style=" font-size: 16px; padding:16px; border-radius: 5px;">微信充值</van-button><van-button class="sign-out" type="primary" size="normal" @click="uplevel(4,2)" style=" font-size: 16px; padding:16px; border-radius: 5px; background-color:#1777ff; margin-left: 3px;;">支付宝充值</van-button></div >
		  
          
      </div >
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[],
	  showpay4:false,
	  showpay3:false,
	  showpay2:false,
	  
    };
  },
  methods: {
	showp(id){
		if(id == 2){
			this.showpay2 = true;
			this.showpay3 = false;
			this.showpay4 = false;
		}
		if(id == 3){
			this.showpay3 = true;
			this.showpay2 = false;
			this.showpay4 = false;
		}
		if(id == 4){
			this.showpay4 = true;
			this.showpay3 = false;
			this.showpay2 = false;
		}
	},
	uplevel(level, paycode){
		this.$http({
		  method: 'post',
		  data:{level:level, paycode: paycode},
		  url: 'uplevel'
		}).then(res=>{
		  if(res.code === 200){
		    
		    location.href=res.data.url;
		    
		  }else if(res.code ===401){
		    this.$toast(res.msg);
		  }
		})
	},
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast('刷新成功');
        this.isLoading = false;
      }, 500);
    },
    getUserWithdrawList(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_list'
      }).then(res=>{
        if(res.code === 200){
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserWithdrawList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .div __track .div __head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}

</style>
